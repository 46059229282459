//default imports
import { useEffect, useState } from "react";

//components
import Button from "components/web/button";

//pages
import Content from "../content";

export default () => {
  const [ContentView, setContentView] = useState("");

  useEffect(async () => {
    setContentView("InfoView");
  }, []);

  const PageStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    overflowY: "hidden",
    overflowX: "hidden",
  };

  const Pages = [
    {
      component: "container",
      style: {
        display: "flex",
        width: "38vw",
        minWidth: "38vw",
        height: "66vh",
        padding: 8,
        backgroundColor: "white",
        borderRadius: 8,
        boxShadow: "4px 4px 24px rgba(0, 0, 0, 0.2)",
      },
      children: [Content(ContentView)],
    },
  ];

  return {
    component: "container",
    style: PageStyle,
    children: Pages,
  };
};
