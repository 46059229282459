//default imports
import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

//importação do tema
import Theme from "theme";

/*

** Como acessar os components nesse ambiente web **

Componentes Global -> "components/global/button";.
-> Componentes propriamente ditos "Globais", que podem ser acessados de todo o ambiente.

Componentes Locais -> "components/local/button";.
-> Componentes propriamente ditos "Locais", que podem ser acessados somente no seguimento onde é criado ex: (admin/client/consumer).

Componentes Web -> "components/web/button";.
-> Componentes propriamente ditos "Web", que podem ser acessados em todo o produto Web.

** Como acessar os assets nesse ambiente web **

ex: import Close from "assets/images/octal/menu/close.svg";

*/

import Intro from "pages/intro";

let Page = () => {
  //estado que define modo mobile
  const [viewMode, setViewMode] = useState("desktop");
  useEffect(async () => {
    const { innerWidth: window_width, innerHeight: window_height } = window;
    const proportion = window_width / window_height;
    if (proportion < 1) setViewMode("mobile");
    else setViewMode("desktop");
    function handleResize() {
      const { innerWidth: window_width, innerHeight: window_height } = window;
      const proportion = window_width / window_height;
      if (proportion < 1) setViewMode("mobile");
      else setViewMode("desktop");
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //retorno do componente
  return Render({
    component: "container",
    style: {
      display: "flex",
      width: "100%",
      height: "100%",
    },
    children: [
      {
        render: (
          <Fade>
            {Render({
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "100%",
              },
              children: [Intro()],
            })}
          </Fade>
        ),
      },
    ],
  });
};

export default Page;
