//default imports
import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components
import Button from "components/web/button";

const PageId = "InfoView";

export default (ContentView) => {
  const PageStyle = {
    display: PageId === ContentView ? "flex" : "none",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  };

  const Info = [
    {
      component: "container",
      style: {
        display: "flex",
        width: "100%",
        height: "50%",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
      },
      children: [
        //text1
        {
          component: "container",
          style: {
            display: "flex",
            width: "auto",
            height: "auto",
            flexDirection: "row",
            gap: 4,
          },
          children: [
            {
              component: "text",
              style: {
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "center",
                lineHeight: "1.5",
                fontFamily: "Poppins",
                color: "green",
              },
              text: "Parabéns, ",
            },
            {
              component: "text",
              style: {
                fontSize: "22px",
                fontWeight: "bold",
                textAlign: "center",
                lineHeight: "1.5",
                fontFamily: "Poppins",
              },
              text: " você concluiu sua primeira etapa de seus desafios!",
            },
          ],
        },
        //text2
        {
          component: "text",
          style: {
            fontSize: "18px",
            width: "100%",
            textAlign: "center",
            lineHeight: "1.5",
            fontFamily: "Poppins",
          },
          text: "Acesse seu perfil no Project e avance para o próximo desafio!",
        },
        //text3
        {
          component: "text",
          style: {
            fontSize: "12px",
            width: "100%",
            textAlign: "center",
            lineHeight: "1.5",
            fontFamily: "Poppins",
            textDecoration: "underline",
            fontStyle: "italic",
            color: "gray",
            marginTop: 12,
          },
          text: "Lembre-se sempre de atualizar o status da sua tarefa!",
        },
        //btn
        {
          component: "container",
          style: {
            display: "flex",
            width: "12vw",
            marginTop: "8vh",
            height: "auto",
            padding: 4,
          },
          children: [
            Button({
              style: {
                button: {
                  width: "100%",
                  height: "100%",
                  borderColor: "#211E40",
                  border: "1px solid",
                  borderRadius: 8,
                },
                label: {
                  fontSize: "1.66vh",
                  fontFamily: "Poppins",
                  color: "#211E40",
                },
              },
              label: "Proxima etapa ->",
              onPress: async () => {
                window.location.href = "https://project.infra.octal.tec.br/my/page";
              },
            }),
          ],
        },
      ],
    },
  ];

  return {
    component: "container",
    style: PageStyle,
    children: Info,
  };
};
